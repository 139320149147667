<template>
  <b-card>
      <h3>Tesoreria del ChatBot</h3>
      <p>Aquí puedes gestionar las Tesoreria del ChatBot.</p>
  </b-card>

</template>

<script>
export default {
  name: 'Tesoreria',
}
</script>